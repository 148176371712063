import React, { useEffect } from 'react'
import { ADSENSE_CLIENT_ID, AD_OPTIONS } from '../../Config/Ads/ads.config'
import { AdCon } from './styles'

type AdProps = {
  bannerType: string
}

export const AdUnit: React.FC<AdProps> = ({ bannerType }) => {
  const banner = AD_OPTIONS[bannerType]
  const isDevEnv = process.env.NODE_ENV === 'development'

  useEffect(() => {
    if(isDevEnv) return
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({})
    } catch (e) {}
  }, [])

  if(isDevEnv) return

  return (
    <>
      {banner && window?.adsbygoogle && window.adsbygoogle.loaded && (
        <AdCon vertMargins={1.5} sideMargins={0.5}>
          {banner.layoutKey ? (
            <ins
              className="adsbygoogle"
              style={{ display: 'block' }}
              data-ad-format="fluid"
              data-ad-layout-key={banner.layoutKey}
              data-ad-client={ADSENSE_CLIENT_ID}
              data-ad-slot={banner.slotId}
            ></ins>
          ) : (
            <ins
              className="adsbygoogle"
              data-ad-client={ADSENSE_CLIENT_ID}
              data-ad-slot={banner.slotId}
            ></ins>
          )}
        </AdCon>
      )}
    </>
  )
}
