import { logEvent } from '../analytics/firAnalytics'

export const createSingleVideoPlaylistStr = (mediaId: string) => {
  return `https://cdn.jwplayer.com/v2/media/${mediaId}`
}

export const sendVideoStart = (params: any) => {
  logEvent('video_start', params)
}

export const sendVideoComplete = (params: any) => {
  logEvent('video_watched', {
    percentage_watched: '1',
    ...params,
  })
}

export const sendVideoPause = (params: any) => {
  logEvent('video_watched', params)
}

export const videoLog = (name: string, params: any) => {
  logEvent(name, params)
}
