export function PLLSearchResult({ hit, components }: { hit: any; components: any }) {
  return (
    <a href={hit.hyperlink} className="aa-ItemLink">
      <div className="aa-ItemContent">
        {hit.profileUrl && (
          <img src={hit.profileUrl} style={{ width: 40, height: 40 }} />
        )}
        <div className="aa-ItemTitle">
          <components.Highlight hit={hit} attribute="fullName" />
        </div>
      </div>
    </a>
  )
}

export function PLLEventSearchResult({
  hit,
  components,
}: {
  hit: any
  components: any
}) {
  return (
    <a href={hit.hyperlink} className="aa-ItemLink">
      <div className="aa-ItemContent">
        <div className="aa-ItemTitle">
          <components.Highlight hit={hit} attribute="title" />
        </div>
      </div>
    </a>
  )
}

export function PLLWPSearchResult({
  hit,
  components,
}: {
  hit: any
  components: any
}) {
  return (
    <a href={hit.permalink} className="aa-ItemLink">
      <div className="aa-ItemContent">
      {hit.images?.thumbnail?.url && (
          <img src={hit.images?.thumbnail?.url} style={{ width: 60, height: 40, borderRadius: 10 }} />
        )}
        <div className="aa-ItemTitle">
          <components.Highlight hit={hit} attribute="post_title" />
        </div>
      </div>
    </a>
  )
}
