import React, { createContext, useState, useEffect, useContext } from 'react'
import { SeasonContext } from '../SeasonContext'
import { EventGQL, Team, getSingleTeamInfoGQL } from '../../Api'
import { Season } from '../SeasonContext/SeasonContext'
import { checkRealTeamByCode, getTeamCode } from '../../Utils/TeamHelpers/teamHelpers'
import { useTeamName } from '../../Routing/utils'
import { EVENTS_YEAR } from '../../Config/League/league.config'

type TeamContextType = {
  isTeamLoading: boolean
  isEventsLoading: boolean
  team: Team | null
  events: EventGQL[]
}

export const TeamContext = createContext<TeamContextType | null>(null)

export const TeamProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const teamIDParam = useTeamName()
  const { seasonSelect } = useContext(SeasonContext)!
  const [teamId, setTeamId] = useState<string | null>(null)
  const [team, setTeam] = useState<Team | null>(null)
  const [yearObj, setYearObj] = useState<Season>(seasonSelect)
  const [isTeamLoading, setIsTeamsLoading] = useState<boolean>(true)
  const [isEventsLoading, setIsEventsLoading] = useState<boolean>(true)
  const [events, setEvents] = useState<EventGQL[]>([])

  useEffect(() => {
    if (teamIDParam.length !== 3) {
      //convert to team id
      let tmId = getTeamCode(teamIDParam)
      if (!tmId) return
      setTeamId(tmId)
      let realTeam = checkRealTeamByCode(tmId)
      if (!realTeam) return
      setTeamId(realTeam)
      fetchData(realTeam, yearObj.year)
    } else {
      let realTeam = checkRealTeamByCode(teamIDParam)
      if (!realTeam) return
      setTeamId(realTeam)
      fetchData(realTeam, yearObj.year)
    }
  }, [])

  useEffect(() => {
    if (!teamId) return
    if (seasonSelect.year !== yearObj.year) {
      // Refetch & update all stats
      fetchData(teamId, seasonSelect.year)
      setYearObj(seasonSelect)
    }
  }, [seasonSelect])

  const fetchData = async (tmId: string, year: number) => {
    return await Promise.all([
      fetchTeamData(tmId, year),
      // fetchBanners()
    ])
  }

  const fetchTeamData = async (tmId: string, year: number) => {
    if (!tmId) return
    try {
      let teamRes = await getSingleTeamInfoGQL(tmId, year, year, EVENTS_YEAR)
      if (!teamRes) {
        setIsTeamsLoading(false)
        setIsEventsLoading(false)
        return
      }
      setTeam(teamRes)
      setIsTeamsLoading(false)
      setIsEventsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <TeamContext.Provider
      value={{
        isTeamLoading: isTeamLoading,
        isEventsLoading: isEventsLoading,
        team: team,
        events: events,
      }}
    >
      {children}
    </TeamContext.Provider>
  )
}
