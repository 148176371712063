import React, { createContext, useState } from 'react'
import { FeedItem } from '../../Api'
import { Overlay } from '../../Components/Layout'
import { getVideoConfig } from './videoConfigDefaults'
import { PLL_VIDEO_LIBRARY } from '../../Config/Video/config'
import {
  createSingleVideoPlaylistStr,
  sendVideoComplete,
  sendVideoPause,
  sendVideoStart
} from '../../Utils/Video/videoUtils'
//@ts-ignore
import JWPlayer from '@jwplayer/jwplayer-react'

type VideoContextType = {
  loadAndShowVideo: (mediaItem: FeedItem) => void
  loading: boolean
}

export const VideoContext = createContext<VideoContextType | null>(null)

export const VideoProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [mediaItem, setMediaItem] = useState<FeedItem | null>(null)
  const [videoFileUrl, setVideoFileUrl] = useState<string | null>(null)
  const [showVideo, setShowVideo] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  // ANALYTICS  //
  const [videoPlayCount, setVideoPlayCounter] = useState<number>(0)
  const [currentTime, setCurrentTime] = useState<number>(0)
  const [duration, setDuration] = useState<number>(0)

  const loadAndShowVideo = (media: FeedItem) => {
    if (media?.videoFileUrl) {
      setVideoFileUrl(media.videoFileUrl)
    }
    setMediaItem(media)
    setShowVideo(!showVideo)
  }
  const handleClose = () => {
    setMediaItem(null)
    setVideoFileUrl(null)
    setShowVideo(!showVideo)
  }

  // ANALYTICS  //
  const onVideoComplete = (event: any) => {
    if (!mediaItem) return
    let params = {
      videoLength: duration > 0 ? duration : '',
      mediaId: mediaItem?.mediaId,
      tags: mediaItem?.tags,
      title: mediaItem?.title,
    }
    sendVideoComplete(params)
  }

  const onVideoStart = (event: any) => {
    if (!mediaItem) return
    if (videoPlayCount > 0) return
    let params = {
      videoLength: duration > 0 ? duration : '',
      mediaId: mediaItem.mediaId,
      tags: mediaItem?.tags,
      title: mediaItem?.title,
    }
    setVideoPlayCounter(1)
    sendVideoStart(params)
  }

  const onVideoTime = (event: any) => {
    if (event?.currentTime && event.currentTime !== 0) {
      setCurrentTime(event.currentTime)
    }
    if (event?.currentTime && event.currentTime !== 0) {
      setDuration(event.duration)
    }
  }

  const onVideoPause = (event: any) => {
    if (!mediaItem?.mediaId) return
    if (currentTime === 0 || duration === 0) return
    let pctWatched = currentTime / duration
    let params = {
      playlist: createSingleVideoPlaylistStr(mediaItem.mediaId),
      mediaId: mediaItem.mediaId,
      seconds_watched: currentTime,
      percentage_watched: pctWatched,
      tags: mediaItem?.tags,
      title: mediaItem?.title,
    }
    sendVideoPause(params)
  }

  return (
    <VideoContext.Provider
      value={{
        loading: loading,
        loadAndShowVideo: loadAndShowVideo,
      }}
    >
      {children}
      {showVideo && mediaItem && (
        <Overlay type="video" onClick={handleClose}>
          <JWPlayer
            onTime={onVideoTime}
            onFirstFrame={onVideoStart}
            onComplete={onVideoComplete}
            onPause={onVideoPause}
            config={getVideoConfig({ isAutoPlay: true, isMuted: false })}
            playerId={'main-player'}
            file={videoFileUrl}
            library={PLL_VIDEO_LIBRARY}
          />
        </Overlay>
      )}
    </VideoContext.Provider>
  )
}
