export const allPlayersWithStatsQuery = `
query($season: Int, $includeZPP: Boolean, $limit: Int) {
  allPlayers(season: $season, includeZPP: $includeZPP, limit: $limit) {
    officialId
    collegeYear
    country
    countryCode
    firstName
    lastName
    lastNameSuffix
    handedness
    injuryDescription
    injuryStatus
    isCaptain
    profileUrl
    experience
    expFromYear
    allYears
    slug
    jerseyNum
    allTeams {
      officialId
      location
      locationCode
      urlLogo
      league
      position
      positionName
      jerseyNum
      year
      fullName
    }
    stats (year: $season, segment: regular) {
      points
      scoringPoints
      faceoffPct
      shots
      shotPct
      shotsOnGoal
      shotsOnGoalPct
      twoPointShots
      twoPointShotPct
      twoPointShotsOnGoal
      twoPointShotsOnGoalPct
      savePct
      onePointGoals
      scoresAgainst
      saa
      gamesPlayed
      goals
      twoPointGoals
      assists
      groundBalls
      turnovers
      causedTurnovers
      faceoffsWon
      faceoffsLost
      faceoffs
      goalsAgainst
      twoPointGoalsAgainst
      numPenalties
      pim
      pimValue
      saves
      powerPlayGoals
      powerPlayShots
      shortHandedGoals
      shortHandedShots
      shortHandedGoalsAgainst
      powerPlayGoalsAgainst
      tof
      goalieWins
      goalieLosses
      goalieTies
      GAA
      twoPtGaa
      plusMinus
      foRecord
      shotTurnovers
      touches
      totalPasses
    }
    postStats: stats(year: $season, segment: post) {
      GAA
      assists
      causedTurnovers
      faceoffPct
      faceoffs
      faceoffsLost
      faceoffsWon
      foRecord
      gamesPlayed
      gamesStarted
      goalieLosses
      goalieTies
      goalieWins
      goals
      goalsAgainst
      groundBalls
      numPenalties
      onePointGoals
      pim
      pimValue
      plusMinus
      points
      powerPlayGoals
      powerPlayGoalsAgainst
      powerPlayShots
      saa
      savePct
      saves
      scoresAgainst
      scoringPoints
      shortHandedGoals
      shortHandedGoalsAgainst
      shortHandedShots
      shotPct
      shots
      shotsOnGoal
      shotsOnGoalPct
      tof
      turnovers
      twoPointGoals
      twoPointGoalsAgainst
      twoPointShots
      twoPointShotPct
      twoPointShotsOnGoal
      twoPointShotsOnGoalPct
      twoPtGaa
      shotTurnovers
      touches
      totalPasses
    }
    champSeries(year: $season) {
      position
      positionName
      team{
        officialId
        locationCode
        location
        position
        positionName
      }
      stats {
        GAA
        assists
        causedTurnovers
        faceoffPct
        faceoffs
        faceoffsLost
        faceoffsWon
        foRecord
        gamesPlayed
        gamesStarted
        goalieLosses
        goalieTies
        goalieWins
        goals
        goalsAgainst
        groundBalls
        numPenalties
        onePointGoals
        scoringPoints
        pim
        pimValue
        plusMinus
        points
        powerPlayGoals
        powerPlayGoalsAgainst
        powerPlayShots
        saa
        savePct
        saves
        scoresAgainst
        shortHandedGoals
        shortHandedGoalsAgainst
        shortHandedShots
        shotPct
        shots
        shotsOnGoal
        shotsOnGoalPct
        tof
        turnovers
        twoPointGoals
        twoPointGoalsAgainst
        twoPointShots
        twoPointShotPct
        twoPointShotsOnGoal
        twoPointShotsOnGoalPct
        twoPtGaa
        touches
        totalPasses
      }
    }
  }
}
`

export const allPlayersStatsQuery = `
query($season: Int, $statType: StatType!) {
  allPlayers(season: $season, statType: $statType) {
    officialId
    slug
		stats {
      points
      scoringPoints
      faceoffPct
      shots
      shotPct
      shotsOnGoal
      shotsOnGoalPct
      twoPointShots
      twoPointShotPct
      twoPointShotsOnGoal
      twoPointShotsOnGoalPct
      savePct
      onePointGoals
      scoresAgainst
      saa
      gamesPlayed
      goals
      twoPointGoals
      assists
      groundBalls
      turnovers
      causedTurnovers
      faceoffsWon
      faceoffsLost
      faceoffs
      goalsAgainst
      twoPointGoalsAgainst
      numPenalties
      pim
      pimValue
      saves
      powerPlayGoals
      powerPlayShots
      shortHandedGoals
      shortHandedShots
      shortHandedGoalsAgainst
      powerPlayGoalsAgainst
      tof
      goalieWins
      goalieLosses
      goalieTies
      GAA
      twoPtGaa
      plusMinus
      foRecord
    }
    careerStats {
      points
      scoringPoints
      faceoffPct
      shotPct
      twoPointShotPct
      twoPointShotsOnGoalPct
      savePct
      shotsOnGoalPct
      onePointGoals
      scoresAgainst
      saa
      gamesPlayed
      goals
      twoPointGoals
      assists
      groundBalls
      turnovers
      causedTurnovers
      faceoffsWon
      faceoffsLost
      faceoffs
      shots
      twoPointShots
      twoPointShotsOnGoal
      goalsAgainst
      twoPointGoalsAgainst
      numPenalties
      pim
      pimValue
      saves
      powerPlayGoals
      powerPlayShots
      shortHandedGoals
      shortHandedShots
      shortHandedGoalsAgainst
      powerPlayGoalsAgainst
      shotsOnGoal
      tof
      goalieWins
      goalieLosses
      goalieTies
      GAA
      twoPtGaa
      plusMinus
      foRecord
    }
  }
}
`

export const allStatLeadersQuery = `
query($year: Int!, $seasonSegment: SeasonSegment, $statList: [String], $limit: Int){
  playerStatLeaders(year: $year, seasonSegment: $seasonSegment, statList: $statList, limit: $limit) {
    officialId
    profileUrl
    firstName
    lastName
    position
    statType
    slug
    statValue
    playerRank
    jerseyNum
    teamId
    year
  }
}
`

export const fetchTeamLeaderByStat = `
query($sortBy: StatFieldName, $season: Int, $statType: StatType!, $limit: Int, $league: String) {
  allPlayers(sortBy:$sortBy, season: $season, statType:$statType, limit: $limit, league: $league) {
		officialId
    firstName
    lastName
    profileUrl
    position
    slug
    allTeams {
      officialId
      location
      locationCode
      urlLogo
      league
      position
      positionName
      jerseyNum
      year
      fullName
    }
    stats {
      points
      onePointGoals
      twoPointGoals
      scoringPoints
      assists
      shotPct
      faceoffPct
      savePct
      groundBalls
      causedTurnovers
    }
  }
}
`

export const fetchPlayerGameLogs = `
query($id: ID, $year: Int) {
  player(id:$id, forYear: $year) {
    officialId
    slug
    currentTeam{
      officialId
    }
    allEvents {
      slugname
      seasonSegment
      startTime
      homeTeam {
        officialId
      }
      awayTeam {
        officialId
      }
      playerEventStats {
        gamesPlayed
        goals
        assists
        points
        turnovers
        shots
        shotPct
        shotsOnGoal
        shotsOnGoalPct
        gamesStarted
        onePointGoals
        twoPointGoals
        saves
        savePct
        goalsAgainst
        twoPointGoalsAgainst
      }
    }
  }
}
`

export const fetchAllTimeLeaderByStat = `
query($stat: String, $limit: Int) {
  careerStats(stat: $stat, limit: $limit) {
    player{
      name
      experience
      allYears
      slug
      currentTeam{
        officialId
        locationCode
        location
      }
    }
    gamesPlayed
    points
    goals
    onePointGoals
    twoPointGoals
    assists
    groundBalls
    saves
    faceoffsWon
  }
}
`

export const fetchAllPlayersAdvancedStats = `
query($year: Int, $limit: Int){
  allPlayers(season: $year, limit: $limit, includeZPP: false) {
    officialId
    firstName
    lastName
    slug
    currentTeam {
      jerseyNum
      position
      officialId
      locationCode
      location
      fullName
      urlLogo
    }
    stats(year: $year, segment: regular) {
      gamesPlayed
      goals
      assists
      shots
      touches
      totalPasses
      turnovers
      passRate
      shotRate
      goalRate
      assistRate
      turnoverRate
    }
    advancedSeasonStats {
      unassistedGoals
      assistedGoals
      settledGoals
      fastbreakGoals
      substitutionGoals
      doorstepGoals
      powerPlayGoals

      assistOpportunities
      settledAssists
      powerPlayAssists
      fastbreakAssists
      dodgeAssists
      pnrAssists

      unassistedShots
      unassistedShotPct
      assistedShots
      assistedShotPct
      pipeShots


    }
  }
}
`

export const playerFantasyStatsQuery = `
query($season: Int!, $week: Int!) {
  allPlayerFantasy(year: $season, week: $week) {
    officialId
    collegeYear
    country
    countryCode
    firstName
    lastName
    lastNameSuffix
    handedness
    injuryDescription
    injuryStatus
    isCaptain
    profileUrl
    experience
    expFromYear
    allYears
    slug
    jerseyNum
    allTeams {
      officialId
      locationCode
      location
      league
      position
      positionName
      jerseyNum
      year
      fullName
      urlLogo
    }
    stats (year: $season, segment: regular) {
      points
      scoringPoints
      faceoffPct
      shots
      shotPct
      shotsOnGoal
      shotsOnGoalPct
      twoPointShots
      twoPointShotPct
      twoPointShotsOnGoal
      twoPointShotsOnGoalPct
      savePct
      onePointGoals
      scoresAgainst
      saa
      gamesPlayed
      goals
      twoPointGoals
      assists
      groundBalls
      turnovers
      causedTurnovers
      faceoffsWon
      faceoffsLost
      faceoffs
      goalsAgainst
      twoPointGoalsAgainst
      numPenalties
      pim
      pimValue
      saves
      powerPlayGoals
      powerPlayShots
      shortHandedGoals
      shortHandedShots
      shortHandedGoalsAgainst
      powerPlayGoalsAgainst
      tof
      goalieWins
      goalieLosses
      goalieTies
      GAA
      twoPtGaa
      plusMinus
      foRecord
      shotTurnovers
    }
    postStats: stats(year: $season, segment: post) {
      assists
      causedTurnovers
      faceoffs
      faceoffsLost
      faceoffsWon
      gamesPlayed
      goalsAgainst
      groundBalls
      onePointGoals
      points
      saves
      scoresAgainst
      turnovers
      twoPointGoals
      twoPointGoalsAgainst
    }
    fantasy: fantasyInfo(week: $week){
        fantasyPlayerInfoId
        salary
        weekPts
        avgPPG
        projectedPoints
        realtimeProjectedPoints
        position
        seasonTotalPts
        gameNumber
    }
  }
}
`
