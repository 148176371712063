import { EventGQL, PlayLogs, WinProbTeam } from '../../Api'
import { GameWinProbability } from '../../Api/events/events.types'
import { PERIOD_SECONDS } from '../../Constants/season'
import { createPlayLogDesc } from '../EventHelpers/playLogHelpers'
import { getTeamColor } from '../TeamHelpers/teamHelpers'
import { Percent2Digits } from '../formatting'

export const getLatestWinProb = (logs: GameWinProbability[], event: EventGQL) => {
  const latestLog = logs[logs.length - 1]
  return generateWinProbObj(latestLog, event)
}

export const getWinProbByTime = (secondsPassed: number, event: EventGQL) => {
  if (!event.liveWinProbablities) return null

  const foundLog = event.liveWinProbablities.find(
    (log) => log.secondsPassed === secondsPassed
  )
  if (!foundLog) return null

  return generateWinProbObj(foundLog, event)
}

const generateWinProbObj = (log: GameWinProbability, event: EventGQL) => {
  let leadingTeamProb: WinProbTeam | null = null

  if (log.awayTeamWinProbability > log.homeTeamWinProbability) {
    leadingTeamProb = {
      team: event.awayTeam.officialId,
      probability: log?.awayTeamWinProbability || 0,
      color: getTeamColor(event.awayTeam.officialId),
    }
  } else {
    leadingTeamProb = {
      team: event.homeTeam.officialId,
      probability: log?.homeTeamWinProbability || 0,
      color: getTeamColor(event.homeTeam.officialId),
    }
  }
  return leadingTeamProb
}

export const getPeriodLabel = (period: number): string => {
  // Determine if it's a quarter or an overtime period
  if (period <= 4) {
    return `Q${period}` // Regular quarters (Q1-Q4)
  } else if (period <= 7) {
    return `OT${period - 4}` // Overtime periods (OT1-OT3)
  } else {
    return 'Game Over' // Time exceeds the normal game length and overtimes
  }
}

export const calcTotalGameTime = (period: number, clockMinutes?: number, clockSeconds?: number): number => {
  if (!period) return 4 * PERIOD_SECONDS // default periods
  if(period > 4) {
    // Overtime periods
    if(!clockMinutes || !clockSeconds) return period * PERIOD_SECONDS

    let prevPeriodSecondsPassed = (period - 1) * PERIOD_SECONDS
    const currentPeriodSecondsPassed = PERIOD_SECONDS - (clockMinutes * 60 + clockSeconds)
    return prevPeriodSecondsPassed + currentPeriodSecondsPassed 

  } 
  console.log('Seconds passed: ', period * PERIOD_SECONDS)
  console.log('Total time: ', 4 * PERIOD_SECONDS)
  return 4 * PERIOD_SECONDS
}

export type PlayLogProbDescObj = {
  probability: number
  playLogs: PlayLogs[]
  event: EventGQL
}

export const createWinProbDesc = ({
  probability,
  playLogs,
  event,
}: PlayLogProbDescObj) => {
  let percent = Percent2Digits(probability)
  let desc = ''
  if (playLogs.length === 1) {
    desc = createPlayLogDesc(playLogs[0], event)
  } else {
    const firstLog = playLogs[0]
    const lastLog = playLogs[playLogs.length - 1]
    if(!lastLog.descriptor) return percent
    if (lastLog.descriptor.includes('SHOT GOOD')) {
      //Show goal before next faceoff
      desc = `${createPlayLogDesc(lastLog, event)} ${createPlayLogDesc(firstLog, event)}`
    } else {
      desc = `${createPlayLogDesc(firstLog, event)} ${createPlayLogDesc(lastLog, event)}`
    }
  }
  return `${percent} ${desc ?? ``}`
}
