import React from 'react'
import { styled } from '@mui/system'
import { colors } from '../../Constants/consts'

type Color = 'white' | 'yellow' | 'dark' | 'grey'
type ColorMapType = { [key in Color]: string }

const colsorMap: ColorMapType = {
  white: colors.white,
  yellow: colors.yellow,
  dark: colors.gray.gray80,
  grey: colors.darkLayout,
}

type styleProps = {
  color: Color
  sideMargins: number
  vertMargins: number
  padding?: number
  bgImg?: string
  rounded?: number
}

const BoxStyle = styled('section')<styleProps>(
  ({ color, vertMargins, sideMargins, padding, bgImg, rounded, theme }) => ({
    background: color ? colsorMap[color] : colors['white'],
    padding:
      padding !== null && padding !== undefined
        ? theme.spacing(padding)
        : theme.spacing(4),
    margin: theme.spacing(vertMargins, sideMargins),
    backgroundImage: bgImg !== '' ? `url(${bgImg})` : 'none',
    backgroundPosition: 'right top',
    backgroundSize: 'cover',
    borderRadius: rounded || 0,
    [theme.breakpoints.down('sm')]: {
      padding:
        padding !== null && padding !== undefined ? theme.spacing(0) : theme.spacing(2),
      margin: theme.spacing(
        vertMargins * 0.75,
        sideMargins * 0.75
      ),
      backgroundPosition: 'right center',
      borderRadius: rounded ? rounded * 0.75 : 0,
    },
  })
)

export type BoxProps = {
  color?: Color
  sideMargins?: number
  vertMargins?: number
  padding?: number
  bgImg?: string
  className?: string
  rounded?: number
  children?: React.ReactNode
}

export const Box: React.FC<React.PropsWithChildren<BoxProps>> = ({
  color = 'white',
  sideMargins = 2,
  vertMargins = 2,
  bgImg,
  padding,
  rounded = 0,
  children,
}) => {
  return (
    <BoxStyle
      color={color as Color}
      sideMargins={sideMargins}
      vertMargins={vertMargins}
      bgImg={bgImg}
      padding={padding}
      rounded={rounded}
    >
      {children}
    </BoxStyle>
  )
}