import { formatProfileUrl } from '../Users'
import { FirebaseUser, User } from '../Users/types'
import { getFirAuth } from './firebaseConnect'

export type AuthData = {
  isLoggedIn: boolean
  isAnonymous: boolean
  uid: string | null
  email: string | null
  phone?: string | null
  profileUrl?: string | null
  firstName?: string | null
  lastName?: string | null
  gender?: string | null
  birthday?: string | null
  tw?: string | null
  zip?: string | null
  topics?: string[] | null
  gamification?: boolean | null
}

export type FavType = 'topics' | 'favPlayers' | 'favTeam'

export const NO_USER: User = {
  isLoggedIn: false,
  isAnonymous: false,
  uid: null,
  email: null,
  favPlayers: [],
  topics: [],
}

/**
 * Get currently logged user data from firebase
 */
export function getCurrentUser(): FirebaseUser {
  const usr = getFirAuth()?.currentUser
  if (usr && usr.uid) {
    // logged in user
    let firUser: FirebaseUser = {
      isLoggedIn: usr.isAnonymous ? false : true,
      isAnonymous: usr.isAnonymous,
      uid: usr.uid,
    }
    if (usr?.displayName) {
      firUser['firstName'] = usr.displayName.split(' ')[0]
      firUser['lastName'] = usr.displayName.split(' ')[1]
    }
    if (usr?.email) {
      firUser['email'] = usr.email
    }
    if (usr?.phoneNumber) {
      firUser['phone'] = usr.phoneNumber
    }
    if (usr?.photoURL) {
      firUser['profileUrl'] = usr.photoURL
    }

    return firUser as User
  }
  // not logged in
  return NO_USER
}

/**
 * Get currently logged user data
 */
export function setUpdatedUserData(userRes: any): User {
  const usr = getFirAuth()?.currentUser
  if (usr && usr.uid) {
    // logged in user
    let userObj: User = {
      isLoggedIn: usr.isAnonymous ? false : true,
      isAnonymous: usr.isAnonymous,
      uid: usr.uid,
      firstName: userRes?.firstName,
      lastName: userRes?.lastName,
      email: userRes?.email,
      profileUrl: userRes?.profileUrl
        ? formatProfileUrl(userRes.profileUrl)
        : '',
      phone: usr?.phoneNumber ? usr.phoneNumber : userRes?.phone ? userRes.phone : '',
      birthday: userRes?.birthday || '',
      tw: userRes?.tw || '',
      zip: userRes?.zip ||'',
      topics: userRes?.topics || [],
      gamification: userRes?.gamification ? userRes.gamification : false,
      favPlayers: userRes?.favPlayers || [],
    }
    if (userRes?.username) {
      userObj['username'] = userRes.username
    }
    if (userRes?.pllNation) {
      userObj['pllNation'] = userRes.pllNation
    }
    if (userRes?.earlyAccess) {
      userObj['earlyAccess'] = userRes.earlyAccess
    }
    if (userRes?.internationalStreaming) {
      userObj['internationalStreaming'] = userRes.internationalStreaming
    }
    return userObj
  }
  // not logged in
  return NO_USER
}

// /**
//  * Flow to update user from sign in link
//  */
// export function updateUserFromEmailLink(email: string): User {
//   const usr = getFirAuth()?.currentUser
//   if (usr && usr.uid) {
//   return {
//     isLoggedIn: true,
//     isAnonymous: false,
//     uid: usr.uid,
//     firstName: '',
//     lastName: '',
//     email: email,
//     phone: usr?.phoneNumber ? usr.phoneNumber : null
//   }
// }
//   // not logged in
//   return NO_USER
// }