declare global {
  interface Window {
    jwplayer: any
  }
}

export type VideoConfigTypes = {
  isMuted?: boolean
  isAutoPlay?: boolean
}

export const getVideoConfig = (configParams: VideoConfigTypes) => {
  return {
    advertising: {
      autoplayadsmuted: false,
      outstream: false,
    },
    aspectratio: '16:9',
    autoPause: {
      viewability: true,
    },
    autostart: configParams.isAutoPlay || false,
    captions: {
      backgroundColor: '#000000',
      backgroundOpacity: 75,
      color: '#FFFFFF',
      edgeStyle: 'none',
      fontFamily: 'sans-serif',
      fontOpacity: 100,
      fontSize: 15,
      windowColor: '#000000',
      windowOpacity: 0,
    },
    cast: {
      appid: '00000000',
    },
    controls: true,
    displayHeading: false,
    displaydescription: false,
    displaytitle: true,
    floating: {
      mode: 'never',
    },
    generateSEOMetadata: false,
    height: 270,
    include_compatibility_script: false,
    intl: {
      en: {
        related: {
          autoplaymessage: '',
          heading: 'More Videos',
        },
      },
    },
    logo: {
      hide: false,
      position: 'top-right',
    },
    mute: configParams.isMuted || false,
    pipIcon: 'disabled',
    playbackRateControls: true,
    preload: 'metadata',
    related: {
      autoplaytimer: 10,
      displayMode: 'shelf',
      onclick: 'play',
      oncomplete: 'autoplay',
    },
    repeat: false,
    skin: {
      controlbar: {
        background: 'rgba(0,0,0,0)',
        icons: 'rgba(255,255,255,0.8)',
        iconsActive: '#FFFFFF',
        text: '#FFFFFF',
      },
      menus: {
        background: '#333333',
        text: 'rgba(255,255,255,0.8)',
        textActive: '#FFFFFF',
      },
      timeslider: {
        progress: '#F2F2F2',
        rail: 'rgba(255,255,255,0.3)',
      },
      tooltips: {
        background: '#FFFFFF',
        text: '#000000',
      },
    },
    stretching: 'uniform',
    width: '100%',
  }
}
