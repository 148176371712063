import React, { useState, useEffect } from 'react'
import { EventGQL } from '../../../../../Api'
import { ScoreCon } from '../../styles'
import { Left } from '../../../../Icons/interface'
import { colors } from '../../../../../Constants/consts'

type ScoreProps = {
  scoreProps: {
    scores: {
      home: number
      away: number
    }
    eventStatus: number
    teamStatus: 'away' | 'home'
  }
}

export const TeamScore: React.FC<React.PropsWithChildren<ScoreProps>> = ({
  scoreProps,
}) => {
  const { scores, eventStatus, teamStatus } = scoreProps
  const checkIfWinningScore = () => {
    if (eventStatus >= 2) {
      //Check if score is higher
      if (teamStatus === 'away' && scores.away > scores.home) return true
      if (teamStatus === 'home' && scores.home > scores.away) return true
    }
    return false
  }

  return (
    <ScoreCon leader={checkIfWinningScore()}>
      <div className="score">{scores[teamStatus]}</div>
      <div className="winIcCon">
        {checkIfWinningScore() && <Left className="winIc" fill={colors.yellow} />}
      </div>
    </ScoreCon>
  )
}
