import { Outlet } from 'react-router-dom'
import { StatsProvider } from '../../Context'

export const StatsContextLayout: React.FC = () => {
  return (
    <StatsProvider>
      <Outlet />
    </StatsProvider>
  )
}
