import React, { useState, useEffect, Suspense, useRef } from 'react'
import { validateEmail } from './authHelpers'
import { ErrorStyle, InputStyle } from './styles'
import { EmailSuccessSnackbar } from '../Snackbar/emailSuccess'
import { logEvent } from '../../Utils'
import { Head2 } from '../Typography/Text.styles'
import { Btn } from '../Buttons/Btn'

type EmailSignInProps = {
  loginInput: string
  handleInputChange: (newVal: string) => string | undefined
  signInWithEmLink: (redirectTo: string, email: string) => Promise<boolean>
}

export const EmailSignIn: React.FC<React.PropsWithChildren<EmailSignInProps>> = ({
  loginInput,
  signInWithEmLink,
  handleInputChange,
}) => {
  const emailRef = useRef<HTMLInputElement>(null)
  const [email, setEmail] = useState<string>('')
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)

  useEffect(() => {
    if (loginInput.length > 0) setEmail(loginInput)
  }, [])

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus()
    }
  }, [email])

  const handleEmailChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let email = e.target.value
    let newVal = handleInputChange(email)
    if (!newVal) {
      setEmail('')
      return
    }
    setEmail(email.toLowerCase())
  }

  const handleEmailSignin = async () => {
    console.log('Email link clicked')
    const validated = validateEmail(email)
    if (!validated.errorMsg) {
      setErrorMsg('')
      console.log('Sending email link')
      const success = await signInWithEmLink(window.location.href, email)
      if (!success) {
        alert('Trouble sending sign in link')
      } else {
        setShowSnackbar(true)
        setEmail('')
        logEvent('email_login_click', {
          path: window.location.href,
        })
      }
    } else {
      setErrorMsg(validated.errorMsg)
      return
    }
  }

  const TextInputStyle = (props) => (
    <Suspense fallback={<div />}>
      <InputStyle {...props} />
    </Suspense>
  )

  return (
    <>
      <Head2 justify="center">Sign In With Email or Phone</Head2>
      <TextInputStyle
        inputRef={emailRef}
        required
        type="string"
        variant="outlined"
        margin="normal"
        id="temailOutlined"
        placeholder="Enter email or phone"
        InputLabelProps={{
          shrink: true,
        }}
        value={email}
        onChange={(e) => {
          handleEmailChange(e)
        }}
      />
      <ErrorStyle>{errorMsg}</ErrorStyle>
      <Btn btnStyle="primary" handleClick={handleEmailSignin}>
        Sign In
      </Btn>
      {showSnackbar && (
        <EmailSuccessSnackbar
          text={'Email link sent. Check your email'}
          color="green"
          show={showSnackbar}
        />
      )}
    </>
  )
}
