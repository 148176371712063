import { getAlgoliaResults } from '@algolia/autocomplete-js'
import { Autocomplete, PLLSearchResult, ShopifySearchResult, PLLEventSearchResult, PLLWPSearchResult } from './index'
import { SEARCH_CONFIG } from '../../Config/Search/search.config'
import { searchClient } from '../../Api/search/Algolia'

export function AutocompleteContainer() {

  const debounced = debouncePromise((items: any) => Promise.resolve(items), 300)

  return (
    <Autocomplete
      openOnFocus={true}
      getSources={() => debounced([
        {
          sourceId: 'pll',
          getItems({ query }: { query: string }) {
            if (query.length < 1) return []
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: SEARCH_CONFIG.pll,
                  query,
                  params: {
                    hitsPerPage: 6,
                    clickAnalytics: true
                  }
                },
                {
                  indexName: 'prod_posts_post',
                  query,
                  params: {
                    hitsPerPage: 2,
                    clickAnalytics: true
                  }
                }
              ],
            })
          },
          templates: {
            header() {
              return <div>
                <span className="aa-SourceHeaderTitle">Search Results</span>
                <div className="aa-SourceHeaderLine" />
              </div>
            },
            item({ item, components }: { item: any, components: any }) {
              if(item.post_title) {
                return <PLLWPSearchResult hit={item} components={components} />
              }
              if (item.fullName) {
                return <PLLSearchResult hit={item} components={components} />
              } else {
                return <PLLEventSearchResult hit={item} components={components} />
              }
            },
            noResults() {
              return 'No results'
            }
          }
        },
        {
          sourceId: 'products',
          getItems({ query }: { query: string }) {
            if (query.length < 1) return []
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: SEARCH_CONFIG.shop,
                  query,
                  params: {
                    hitsPerPage: 3,
                    clickAnalytics: true
                  }
                },
              ],
            })
          },
          templates: {
            header() {
              return <div>
                <span className="aa-SourceHeaderTitle">Shop Results</span>
                <div className="aa-SourceHeaderLine" />
              </div>
            },
            item({ item, components }: { item: any, components: any }) {
              return <ShopifySearchResult hit={item} components={components} />
            },
            noResults() {
              return 'No results'
            }
          }
        }
      ])}
    />
  )
}

// For delaying search until user stops typing
function debouncePromise(fn: any, time: number) {
  let timerId: any = undefined;

  return function debounced(...args: any) {
    if (timerId) {
      clearTimeout(timerId);
    }

    return new Promise((resolve) => {
      timerId = setTimeout(() => resolve(fn(...args)), time);
    });
  };
}