import React, { createContext, useContext, useEffect, useState } from 'react'
import { AdOptionType } from '../../Config/Ads/ads.config'

interface AdContextProps {
  scriptLoaded: boolean
  canShowAds: boolean
  showRampAdsForPage: (adOptions: AdOptionType[]) => void
}

const AdContext = createContext<AdContextProps | null>(null)
export const useAdContext = () => useContext(AdContext)!

export const AdProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [canShowAds, setCanShowAds] = useState(false)
  const AD_SCRIPT_SRC = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3201393185455772'

  useEffect(() => {
    initAdsWindow()
    if (document.querySelector(`script[src="${AD_SCRIPT_SRC}"]`)) {
      setScriptLoaded(true)
      return
    }
    const script = document.createElement('script')
    script.src = AD_SCRIPT_SRC
    script.async = true
    script.onload = () => {
      setScriptLoaded(true)
    }
    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [])

  const initAdsWindow = () => {
    window.adsbygoogle = window.adsbygoogle || []
  }

  const showRampAdsForPage = (adOptions: AdOptionType[]) => {
    return
  }

  return (
    <AdContext.Provider
      value={{ scriptLoaded, canShowAds, showRampAdsForPage }}
    >
      {children}
    </AdContext.Provider>
  )
}