import { styled } from '@mui/system'


const MOBILE_GRADIENT_WIDTH = 0
export type StyleProps = {
  noMargin: boolean
  desktopWidth: number | string
  tabletWidth: number | string
  mobileWidth: number | string
  smMobileWidth: number | string
}

export const SwiperCon = styled('div')<StyleProps>(
  ({ noMargin, desktopWidth, tabletWidth, mobileWidth, smMobileWidth, theme }) => ({
    width: '100%',
    '& .swiperSlide': {
      width: desktopWidth,
      marginRight: noMargin ? 0 : theme.spacing(3),
      '&:last-child': {
        marginRight: noMargin ? 0 : theme.spacing(2),
      },
      [theme.breakpoints.between('md', 'lg')]: {
        width: tabletWidth,
      },
      [theme.breakpoints.between('xs', 'md')]: {
        width: mobileWidth,
      },
      [theme.breakpoints.down('xs')]: {
        width: smMobileWidth,
      },
    },
  })
)

export const PrevEl = styled('div')(({ theme }) => ({
  '&::after': {
    opacity: 1,
    transition: 'opacity 0.5s',
    position: 'absolute',
    display: 'block',
    pointerEvents: 'none',
    top: 0,
    left: 0,
    width: 0,
    height: '100%',
    zIndex: 1,
    content: '""',
    background: 'linear-gradient(90deg, rgb(255,255,255), transparent)',
    [theme.breakpoints.down('sm')]: {
      width: MOBILE_GRADIENT_WIDTH,
    },
  },
  '&.hideme::after': { opacity: 0 },
}))

export const NextEl = styled('div')(({ theme }) => ({
  '&::after': {
    opacity: 1,
    transition: 'opacity 0.5s',
    position: 'absolute',
    display: 'block',
    pointerEvents: 'none',
    top: 0,
    left: 0,
    width: 0,
    height: '100%',
    zIndex: 1,
    content: '""',
    background: 'linear-gradient(90deg, rgb(255,255,255), transparent)',
    [theme.breakpoints.down('sm')]: {
      width: MOBILE_GRADIENT_WIDTH,
    },
  },
  '&.hideme::after': { opacity: 0 },
}))

