import {
  defensiveSeasonStatsMap,
  faceoffSeasonStatsMap,
  getStatAvg,
  goalieSeasonStatsMap,
  offensiveSeasonStatsMap,
} from './statsProcess'
import {
  defaultPlayerFavsPrimary,
  defaultPlayerFavsSecondary,
  defaultPlayerFavsThree,
} from '../../Context/constants/playerConstants'
import type { PlayersStatsData, PlayerData, PlayerWithStatsData } from '../../Api'
import { STATS_YEAR } from '../../Config/League/league.config'
import { TeamPlayers } from '../../Api/teams/teams.types'
import {
  FlattenedPlayer,
  LeagueLeader,
  PlayerEventStats,
  PlayerTeam,
} from '../../Api/players/players.types'
import { LeaderBoundary } from './leaderConstants'
import { teamLocationCodes } from '../../Constants/TeamConstants'
import { OPERATORS, StatFilterOption } from '../../Constants/consts'

export type PlayerSortKeys = keyof PlayerWithStatsData
export type PlayerStatsSortKeys = keyof PlayersStatsData
export type TeamPlayerSortKeys = keyof PlayerEventStats

/**
 * Returns player name formatted as "F. Last"
 */
export const formatPlayerName = (first?: string, last?: string) => {
  if (!first) return last || ''
  if (first.length > 2) {
    let firstInitial = first.charAt(0)
    return `${firstInitial}. ${last}`
  }
  // Name like TD Ierlan
  return `${first} ${last}`
}

export function mapPlayerStat(stat: string): string {
  switch (stat) {
    case 'gamesPlayed':
      return 'Games'
    case 'points':
      return 'Points'
    case 'assists':
      return 'Assists'
    case 'groundBalls':
      return 'Groundballs'
    case 'causedTurnovers':
      return 'Caused Turnovers'
    case 'faceoffsWon':
      return 'Faceoff Wins'
    case 'faceoffPct':
      return 'Faceoff %'
    case 'saves':
      return 'Saves'
    case 'savePct':
      return 'Save %'
    case 'onePointGoals':
      return '1Pt Goals'
    case 'twoPointGoals':
      return '2Pt Goals'
    case 'goals':
      return 'Total Goals'
    case 'scoringPoints':
      return 'Scoring Points'
    case 'touches':
      return 'Touches'
    case 'shotPct':
      return 'Shot %'
    case 'shots':
      return 'Shots'
    case 'FPTS':
      return 'Szn Fantasy Points'
    case 'FPTS/G':
      return 'Fantasy Pts/GM'
    case 'pointsPG':
      return 'Points/GM'
    case 'onePointGoalsPG':
      return '1G/GM'
    case 'shotsPG':
      return 'Shots/GM'
    case 'assistsPG':
      return 'Assists/GM'
    case 'savesPG':
      return 'Saves/GM'
    case 'faceoffWinsPG':
      return 'FO Wins/GM'
    case 'causedTurnoversPG':
      return 'Caused Turnovers/GM'
    case 'groundBallsPG':
      return 'Groundballs/GM'
    case 'touchesPG':
      return 'Touches/GM'
    default:
      return ''
  }
}

export const mapPlayerHeaders = (position: string) => {
  switch (position) {
    case 'A':
      return offensiveSeasonStatsMap
    case 'M':
      return offensiveSeasonStatsMap
    case 'SSDM':
      return defensiveSeasonStatsMap
    case 'LSM':
      return defensiveSeasonStatsMap
    case 'FO':
      return faceoffSeasonStatsMap
    case 'F':
      return offensiveSeasonStatsMap
    case 'G':
      return goalieSeasonStatsMap
    default:
      return offensiveSeasonStatsMap
  }
}

export const positionConvert = (positionName: string) => {
  switch (positionName) {
    case 'Attack':
      return 'A'
    case 'Midfield':
      return 'M'
    case 'Defensive Midfield':
      return 'SSDM'
    case 'Long Stick Midfield':
      return 'LSM'
    case 'Short Stick Defensive Midfield':
      return 'SSDM'
    case 'Defense':
      return 'D'
    case 'Faceoff':
      return 'FO'
    case 'Field':
      return 'F'
    case 'Goalie':
      return 'G'
    case 'Rookie':
      return 'R'
    default:
      return ''
  }
}

export const mapPlayerHeadersByFullPosition = (position: string) => {
  switch (position) {
    case 'Attack':
      return offensiveSeasonStatsMap
    case 'Midfield':
      return offensiveSeasonStatsMap
    case 'Defensive Midfield':
      return defensiveSeasonStatsMap
    case 'Long Stick Midfield':
      return defensiveSeasonStatsMap
    case 'Defense':
      return defensiveSeasonStatsMap
    case 'Faceoff':
      return faceoffSeasonStatsMap
    case 'Field':
      return offensiveSeasonStatsMap
    case 'Goalie':
      return goalieSeasonStatsMap
    case 'Rookie':
      return offensiveSeasonStatsMap
    default:
      return offensiveSeasonStatsMap
  }
}

export const sortFunc = (list: any[], sortBy: PlayerSortKeys, ascDesc: -1 | 1) => {
  return list.sort((a, b) => {
    const A = a[sortBy]
    const B = b[sortBy]
    const numA = typeof B === 'number' ? B : 0
    const numB = typeof A === 'number' ? A : 0
    if (ascDesc < 0) {
      return numA - numB
    } else {
      return numB - numA
    }
  })
}

export const sortByText = (list: any[], sortBy: PlayerSortKeys, ascDesc: -1 | 1) => {
  return list.sort((a, b) => {
    const A = a[sortBy]
    const B = b[sortBy]
    if (ascDesc < 0) {
      return A.localCompare(B)
    } else {
      return B.localCompare(A)
    }
  })
}

export const applyLeaderBoundaries = (
  plrs: PlayerData[],
  stat: string,
  segment: string
) => {
  const filteredLeaders = setLeaderBoundaries(plrs, stat, segment)
  const ldrs = filteredLeaders.map((row) => {
    const result = {
      ...row,
      statName: mapPlayerStat(stat),
      stat: stat,
    }
    return result
  })
  return ldrs
}

const getStatsObj = (segment: string, player: PlayerData) => {
  let statsObj =
    segment === 'post'
      ? player?.postStats || null
      : segment === 'champseries'
      ? player?.champSeries?.stats || null
      : player?.stats
      ? player.stats
      : null

  return statsObj
}

const filterByStatAndSegment = (
  list: PlayerData[],
  statsType: string,
  segment: string,
  conditionFunc: Function
) => {
  let l = list.filter((player) => {
    let pObj = getStatsObj(segment, player)
    return pObj && statsType in pObj && conditionFunc(pObj, statsType)
  })
  return l
}

export const setLeaderBoundaries = (
  list: PlayerData[],
  statsType: string,
  segment: string
) => {
  let conditionFunc: Function

  switch (statsType) {
    case 'shotPct':
    case 'shotsOnGoalPct':
    case 'twoPointShotPct':
    case 'twoPointShotsOnGoalPct':
      conditionFunc = (stats: PlayersStatsData, statsType: string) =>
        getStatAvg(stats?.shots, stats?.gamesPlayed) >
          (segment === 'regular' ? 2.5 : 2) &&
        stats[statsType] <= LeaderBoundary[statsType]
      break
    case 'savePct':
    case 'faceoffPct':
      conditionFunc = (stats: PlayersStatsData, statsType: string) =>
        stats[statsType] <= LeaderBoundary[statsType]
      break

    default:
      conditionFunc = (stats: PlayersStatsData, statsType: string) =>
        stats[statsType] > 0
  }
  return filterByStatAndSegment(list, statsType, segment, conditionFunc)
}

export const filterPlayersWithRegisteredStat = (
  players: PlayerData[],
  statType: string
) => {
  switch (statType) {
    case 'points':
      return players.filter(
        (player) =>
          (player.stats?.shots && player.stats.shots > 0) ||
          (player.stats?.assists && player.stats.assists > 0)
      )
    case 'faceoffsWon':
      return players.filter(
        (player) => player.stats?.faceoffs && player.stats.faceoffs > 0
      )

    case 'saves':
      return players.filter(
        (player) =>
          (player?.team?.position === 'G' &&
            player.stats?.saves &&
            player.stats.saves > 0) ||
          (player.stats?.goalsAgainst && player.stats.goalsAgainst > 0) ||
          (player?.team?.position === 'G' &&
            player.stats?.groundBalls &&
            player.stats.groundBalls > 0)
      )
    default:
      return players
  }
}

export const flattenAllPlayerObjs = (players: PlayerData[], segment: string) => {
  let flatList: FlattenedPlayer[] = []
  players.map((p) => {
    if (segment === 'champseries' && !p.champSeries) return
    let statsToFlatten =
      segment === 'post'
        ? p.postStats
        : segment === 'champseries' && p?.champSeries?.stats
        ? p.champSeries.stats
        : p.stats
    const flattened: FlattenedPlayer = {
      officialId: p.officialId,
      slug: p.slug,
      firstName: p.firstName,
      lastName: p.lastName,
      lastNameSuffix: p.lastNameSuffix,
      profileUrl: p.profileUrl,
      jerseyNum: p.jerseyNum,
      position: p.position,
      team: p?.team,
      ...statsToFlatten,
    }
    flatList.push(flattened)
    return
  })
  return flatList
}

export const filterPlayerTable = (
  list: FlattenedPlayer[],
  statsType: PlayerSortKeys,
  segment: string
) => {
  let filtered = list
  switch (statsType) {
    case 'savePct':
      filtered = list.filter(
        (player) =>
          player?.saves &&
          player.saves / (player.gamesPlayed || 1) > 2 &&
          player.savePct < 0.85
      )
      break
    case 'saa':
      filtered = list.filter((player) => player?.saa && player.saa > 0)
      break
    case 'faceoffPct':
      filtered = list.filter(
        (player) => player?.faceoffPct < 0.9 && player.faceoffs > player.gamesPlayed * 2
      )
      break
    case 'shotPct':
    case 'shotsOnGoalPct':
    case 'twoPointShotPct':
    case 'twoPointShotsOnGoalPct':
      filtered = list.filter((player) => player[statsType] < 1)
      break
    case 'scoresAgainst':
    case 'twoPointGoalsAgainst':
      filtered = list.filter(
        (player) => player.saves / (player.gamesPlayed || 1) > 2 && player[statsType] > 0
      )
      break
    default:
      break
  }
  return filtered
}

export const filterAdvancedPlayerTable = (list: any[], filters: StatFilterOption[]) => {
  const gamesPlayedFilter = {
    key: 'gamesPlayed',
    operator: OPERATORS.gt,
    value: 0,
  }
  if (!filters.some((filter) => filter.key === gamesPlayedFilter.key)) {
    filters.push(gamesPlayedFilter)
  }

  return list.filter((player) =>
    filters.every((filter) => {
      const value = player[filter.key]
      switch (filter.operator) {
        case OPERATORS.eq:
          return value === filter.value
        case OPERATORS.gte:
          return value >= filter.value
        case OPERATORS.gt:
          return value > filter.value
        case OPERATORS.lt:
          return value < filter.value
        case OPERATORS.lte:
          return value <= filter.value
        default:
          return true
      }
    })
  )
}

export const sortPlayerTable = (
  list: any[],
  statsType: PlayerSortKeys,
  segment: string = 'regular'
) => {
  switch (statsType) {
    case 'savePct':
      const sl = list.filter((player) => player[statsType] < 0.85)
      if (sl.length < 1) return list
      return sortPlayerFunc(sl, 'savePct', segment, -1)
    case 'faceoffPct':
      const fl = list.filter(
        (player) => player[statsType] < 0.9 && player.faceoffs > player.gamesPlayed * 2
      )
      if (fl.length < 1) return list
      return sortPlayerFunc(fl, 'faceoffPct', segment, -1)
    case 'shotPct':
      const shl = list.filter((player) => player[statsType] < 1)
      if (shl.length < 1) return list
      return sortPlayerFunc(shl, 'shotPct', segment, -1)
    case 'position':
      return sortPlayerByPosition(list)
    default:
      return sortPlayerFunc(list, statsType, segment, -1)
  }
}

export const sortPlayerByPosition = (list: any[]) => {
  return list.sort((a, b) => {
    if (!a?.team || !b?.team) return a > b
    return a.team.position.localCompare(b.team.position)
  })
}

export const sortPlayerFunc = (
  list: PlayerData[],
  sortBy: PlayerSortKeys,
  seasonSegment: string,
  ascDesc: -1 | 1
) => {
  return list.sort((a, b) => {
    let aStatsToSort =
      seasonSegment === 'post'
        ? a.postStats
        : seasonSegment === 'champseries'
        ? a.champSeries?.stats
        : a.stats
    let bStatsToSort =
      seasonSegment === 'post'
        ? b.postStats
        : seasonSegment === 'champseries'
        ? b.champSeries?.stats
        : b.stats
    const A = aStatsToSort ? aStatsToSort[sortBy] : 0
    const B = bStatsToSort ? bStatsToSort[sortBy] : 0
    const numA = typeof B === 'number' ? B : 0
    const numB = typeof A === 'number' ? A : 0
    if (ascDesc < 0) {
      return numA - numB
    } else {
      return numB - numA
    }
  })
}

export const sortTeamPlayerFunc = (
  list: TeamPlayers[],
  sortBy: TeamPlayerSortKeys,
  ascDesc: -1 | 1
) => {
  return list.sort((a, b) => {
    const A = a.stats ? a.stats[sortBy] : 0
    const B = b.stats ? b.stats[sortBy] : 0
    const numA = typeof B === 'number' ? B : 0
    const numB = typeof A === 'number' ? A : 0
    if (ascDesc < 0) {
      return numA - numB
    } else {
      return numB - numA
    }
  })
}

export const sortPlayerCSVFunc = (
  list: any[],
  sortBy: PlayerSortKeys,
  seasonSegment: string,
  ascDesc: -1 | 1
) => {
  return list.sort((a, b) => {
    let aStatsToSort =
      seasonSegment === 'post'
        ? a.postStats
        : seasonSegment === 'champseries'
        ? a.champSeries?.stats
        : a.stats
    let bStatsToSort =
      seasonSegment === 'post'
        ? b.postStats
        : seasonSegment === 'champseries'
        ? b.champSeries?.stats
        : b.stats
    const A = aStatsToSort ? aStatsToSort[sortBy] : 0
    const B = bStatsToSort ? bStatsToSort[sortBy] : 0
    const numA = typeof B === 'number' ? B : 0
    const numB = typeof A === 'number' ? A : 0
    if (ascDesc < 0) {
      return numA - numB
    } else {
      return numB - numA
    }
  })
}

export const getGameRatio = (gamesPlayed: number, value: number, multiple: number) => {
  const compare = gamesPlayed * multiple
  if (value >= compare) {
    return true
  } else {
    return false
  }
}

//Single player mapping
export const mapSinglePlayerObj = (player: PlayerData, year: number) => {
  if (!player) return null
  const tm = getPlayerTeam(year, player)
  if (!tm) {
    return null
  } else {
    return {
      ...player,
      team: tm,
    }
  }
}

//Single player mapping
export const mapTeamPlayerObj = (player: TeamPlayers, year: number) => {
  if (!player) return null
  const tm = getPlayerTeam(year, player)
  if (!tm) {
    return null
  } else {
    return {
      ...player,
      team: tm,
    }
  }
}

export const extractPlayerTeam = (
  players: PlayerData[],
  year: number,
  teamCode?: string
) => {
  return players.map((plr) => {
    let tm: PlayerTeam | null = null
    tm = getLeaderTeam(year, plr, teamCode)
    plr.team = tm
    return plr
  })
}

export const getPlayerTeam = (year: number, player: PlayerData | TeamPlayers) => {
  if (!player?.allTeams || player?.allTeams.length < 1) return null
  const team = player.allTeams.find((tm) => tm.year === year)
  // return latest team if no team
  if (!team) return player.allTeams[0]
  return team
}

export const getPlayerTeamCode = (
  year: number,
  player: PlayerData,
  segment?: string
) => {
  if (segment === 'champseries' && player?.champSeries) {
    let team = getLeaderChampSeriesTeam(player, year)
    return team?.locationCode || team?.officialId
  }
  if (!player?.allTeams || player?.allTeams.length < 1) return null
  const team = player.allTeams.find((tm) => tm.year === year)
  // return latest team if no team
  if (!team) return player.allTeams[0].locationCode || player.allTeams[0].officialId
  return team.locationCode || team.officialId
}

export const getPlayerTeamCodeByTeamId = (year: number, teamId: string) => {
  return year >= 2024 ? teamLocationCodes[teamId] : teamId
}

export const getLeaderTeam = (year: number, player: PlayerData, teamCode?: string) => {
  if (!player?.allTeams) return null
  let team = player?.allTeams.find((tm) => tm.year === year)
  if (!team) return null
  if (teamCode) {
    //To avoid fetching Champ Series data
    team.officialId = teamCode
  }
  return team
}

export const getLeaderChampSeriesTeam = (player: PlayerData, year: number) => {
  const csObj = player?.champSeries
  if (csObj && csObj?.team) {
    csObj.officialId = csObj.team.officialId
    csObj.jerseyNum = player?.jerseyNum
    csObj.year = year
    if (csObj.team.locationCode) {
      csObj.locationCode = csObj.team.locationCode
    }
    return csObj
  }
  return null
}

export const checkActivePlayer = (recentYear: number) => {
  return recentYear === STATS_YEAR ? true : false
}

export const mapPlayersToCSV = (players: any[], segment: string) => {
  const cleaned = players.map((plr) => {
    let stats =
      segment === 'champseries' && plr.champSeries?.stats
        ? plr.champSeries.stats
        : segment === 'post'
        ? plr.postStats
        : plr.stats
    const player = {
      firstName: plr.firstName,
      lastName: plr.lastName,
      jerseyNum: plr?.team?.jerseyNum,
      position: plr?.team?.position,
      team: plr?.team?.fullName,
      gamesPlayed: stats.gamesPlayed,
      points: stats.points,
      goals: stats.goals,
      onePointGoals: stats.onePointGoals,
      twoPointGoals: stats.twoPointGoals,
      scoringPoints: stats.scoringPoints,
      assists: stats.assists,
      shots: stats.shots,
      shotPct: stats.shotPct,
      shotsOnGoal: stats.shotsOnGoal,
      shotsOnGoalPct: stats.shotsOnGoalPct,
      twoPointShots: stats.twoPointShots,
      twoPointShotPct: stats.twoPointShotPct,
      twoPointShotsOnGoal: stats.twoPointShotsOnGoal,
      groundBalls: stats.groundBalls,
      turnovers: stats.turnovers,
      touches: stats.touches,
      totalPasses: stats.totalPasses,
      causedTurnovers: stats.causedTurnovers,
      faceoffs: stats.faceoffs,
      faceoffsWon: stats.faceoffsWon,
      faceoffsLost: stats.faceoffsLost,
      faceoffPct: stats.faceoffPct,
      saves: stats.saves,
      savePct: stats.savePct,
      scoresAgainst: stats.scoresAgainst,
      saa: stats.saa,
      twoPointGoalsAgainst: stats.twoPointGoalsAgainst,
      twoPtGaa: stats.twoPtGaa,
      tof: stats.tof,
      numPenalties: stats.numPenalties,
      pim: stats.pimValue,
      powerPlayGoals: stats.powerPlayGoals,
      powerPlayShots: stats.powerPlayShots,
      shortHandedGoals: stats.shortHandedGoals,
      shortHandedShots: stats.shortHandedShots,
      powerPlayGoalsAgainst: stats.powerPlayGoalsAgainst,
    }
    return player
  })
  return cleaned
}

export const generateDefaultFavs = () => {
  const num = Math.floor(Math.random() * 3)
  switch (num) {
    case 0:
      return defaultPlayerFavsPrimary
    case 1:
      return defaultPlayerFavsSecondary
    case 2:
      return defaultPlayerFavsThree
    default:
      return defaultPlayerFavsPrimary
  }
}

/**
 *
 * Getting player's rank in a stat category
 */
// //TODO: Optimize
export const getRank = (playerNumber: number, plrs: LeagueLeader[]) => {
  switch (playerNumber) {
    case 1:
      return plrs[0]?.statValue === plrs[1]?.statValue ? '[T-1st]' : '[1st]'
    case 2:
      return plrs[1]?.statValue === plrs[0]?.statValue
        ? '[T-1st]'
        : plrs[2]?.statValue === plrs[1]?.statValue
        ? '[T-2nd]'
        : '[2nd]'
    case 3:
      return plrs[2].statValue === plrs[0].statValue
        ? '[T-1st]'
        : plrs[2].statValue === plrs[1].statValue
        ? '[T-2nd]'
        : plrs[2].statValue === plrs[3].statValue
        ? '[T-3rd]'
        : '[3rd]'
    case 4:
      return plrs[3].statValue === plrs[0].statValue
        ? '[T-1st]'
        : plrs[3].statValue === plrs[1].statValue
        ? '[T-2nd]'
        : plrs[3].statValue === plrs[2].statValue
        ? '[T-3rd]'
        : plrs[3].statValue === plrs[4].statValue
        ? '[T-4th]'
        : '[4th]'
    case 5:
      return plrs[4].statValue === plrs[0].statValue
        ? '[T-1st]'
        : plrs[4].statValue === plrs[1].statValue
        ? '[T-2nd]'
        : plrs[4].statValue === plrs[2].statValue
        ? '[T-3rd]'
        : plrs[4].statValue === plrs[3].statValue
        ? '[T-4th]'
        : '[5th]'
    default:
      return ''
  }
}
