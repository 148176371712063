import React, { useEffect, useState } from 'react'
import { SignInModal } from '../../Components/Auth'
import { getFirAuth, PROVIDERS, getActionCodeSettings } from './firebaseConnect'
import {
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithCredential,
  getRedirectResult,
  signInWithRedirect,
  signInWithPopup,
  signInAnonymously,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  Auth,
  ConfirmationResult,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithCustomToken,
} from 'firebase/auth'
import { getCurrentUser, NO_USER, setUpdatedUserData } from './AuthData'
import {
  checkIfEmbededInNativeApp,
  logEvent,
  resetWindowLocationWithoutParams,
} from '../../Utils'
import { useQuery } from '../../Routing'
import { getOrCreateUser } from '../Users/users'
import { User } from '../Users/types'
import { logBrazeEvent } from '../../Utils/analytics/brazeAnalytics'
import { BRAZE_EVENTS } from '../../Constants/analytics'

type AuthContextType = User & {
  authLoading: boolean
  phoneSentResult: ConfirmationResult | null
  recaptchaVerifier: RecaptchaVerifier | null
  brazeInitialized: boolean
  login: (extraFields?: string[]) => void
  logout: () => void
  handleToggleLogin: () => void
  updateProfileInState: (userItem: any) => void
  getAuthToken: () => Promise<string | null | undefined>
  sendPhoneVerification: (phone: string) => Promise<ConfirmationResult | null>
  getMissingUserFields: (fields: string[]) => string[]
}

export const AuthContext = React.createContext<AuthContextType | null>(null)

type AuthProviderProps = { allowAnonymous?: boolean; children?: React.ReactNode }

export const AuthProvider: React.FC<AuthProviderProps> = ({
  allowAnonymous = false,
  children,
}) => {
  let query = useQuery()
  const [user, setUser] = useState<User>(NO_USER)
  const [authLoading, setAuthLoading] = useState<boolean>(true)
  const [showLogin, setShowLogin] = useState<{
    show: boolean
    extraFields?: string[]
  }>({ show: false, extraFields: [] })
  const [phoneSentResult, setPhoneSentResult] = useState<ConfirmationResult | null>(null)
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>(
    null
  )
  const [brazeInitialized, setBrazeInitialized] = useState<boolean>(false)

  useEffect(() => {
    //Check if pending redirect
    getAuthRedirectResult()
    onAuthChange() //Turn on auth listener
    //Cleanup
    return () => onAuthChange()
  }, [])

  /**
   * When the auth state changes, either user is logged in or logged out
   */
  const onAuthChange = () => {
    const auth = getFirAuth()
    if (auth) {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        //Email link signup
        handleEmailLink()
      }
      //Check custom token query param
      let fbctQuery = query.get('fbct')
      if (fbctQuery) {
        handleCustomToken(fbctQuery)
      }

      onAuthStateChanged(auth, (userRes) => {
        setAuthLoading(true)

        if (userRes) {
          // User is logged in
          const usr = getCurrentUser() //Firebase Auth user
          if (!usr.isAnonymous && usr.isLoggedIn) {
            getOrCreateUser(usr).then((dbUser: User) => {
              //User existed or was added, update in state
              setUserDataInState(dbUser)
              if (usr.uid && user.uid !== usr.uid) {
                //lazy load braze
                import('../../Config/Braze/braze.config').then((braze) => {
                  let success = braze.initBraze(usr.uid!)
                  if (success) {
                    setBrazeInitialized(true)
                  }
                })
              }
            })
          } else {
            setUser(usr as User)
            setShowLogin({ show: false, extraFields: [] })
            setAuthLoading(false)
          }
        } else {
          // User is logged out

          if (allowAnonymous) {
            signInAnonymously(auth)
          }
          setShowLogin({ show: false, extraFields: [] })
          setAuthLoading(false)
        }
      })
    } else {
      setUser(NO_USER)
      setAuthLoading(false)
      setShowLogin({ show: false, extraFields: [] })
      if (checkIfEmbededInNativeApp()) {
        logEvent(`firebase_not_initialized_onAuthChange_embeded`)
      } else {
        logEvent(`firebase_not_initialized_onAuthChange`)
      }
    }
  }

  const setUserDataInState = (userRes: User) => {
    const userAuth = setUpdatedUserData(userRes)
    setUser(userAuth)
    setAuthLoading(false)
  }

  /**
   * Update profile in state
   */
  const updateProfileInState = (userItem: any) => {
    const currentAuth = { ...user }
    if (userItem.favTeam) {
      setUser({ ...currentAuth, ...userItem })
      return
    }
    setUser({ ...currentAuth, ...userItem })
    return
  }

  const handleEmailLink = async () => {
    const auth = getFirAuth()
    if (auth) {
      let email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        const em = window.prompt('Please confirm your email')
        if (em) {
          email = em.toLowerCase()
        }
      }
      try {
        const result = await signInWithEmailLink(auth, email!, window.location.href)
        window.localStorage.removeItem('emailForSignIn')
      } catch (err) {
        logEvent('loginError_handleEmailLink', {
          provider: 'email',
          error: err,
          product: 'stats',
        })
        console.log(err)
      }
    }
  }

  const signInWithSocial = async (provider: string) => {
    const auth = getFirAuth()
    if (auth) {
      const p = PROVIDERS.google
      if (!p) return
      try {
        if (provider === 'google') {
          await signInWithGooglePopup(auth)
        } else {
          await signInWithRedirect(auth, p)
        }
      } catch (err: any) {
        console.log(err)
        logEvent('loginError_signInWithSocial', {
          provider: provider,
          error: err,
          product: 'web',
        })
        setShowLogin({ show: !setShowLogin, extraFields: [] })
        throw new Error(err)
      }
    }
  }

  /**
   * Use sign up with popup for just Gmail
   */
  const signInWithGooglePopup = async (auth: Auth) => {
    try {
      let result = await signInWithPopup(auth, PROVIDERS.google)
      if (result?.user?.uid) {
        loginWithBraze(result.user.uid)
      }
    } catch (err: any) {
      logEvent('loginError_signInWithGooglePopup', {
        provider: 'google',
        error: err,
        product: 'web',
      })
      GoogleAuthProvider.credentialFromError(err)
    }
  }

  const getAuthRedirectResult = async () => {
    const auth = getFirAuth()
    if (auth) {
      try {
        let result = await getRedirectResult(auth)
        if (result?.user?.uid) {
          loginWithBraze(result.user.uid)
        }
      } catch (err: any) {
        let errorCode = err.code
        let errorMessage = err.message
        if (!err.credential) return
        await signInWithCredential(auth, err.credential)
      }
    }
  }

  /**
   * Handle if custom token is passed in
   */
  const handleCustomToken = async (tk: string) => {
    const auth = getFirAuth()
    if (auth) {
      try {
        const result = await signInWithCustomToken(auth, tk)
        if (result?.user?.uid) {
          loginWithBraze(result.user.uid)
          resetWindowLocationWithoutParams(['fbct'])
        }
      } catch (err) {
        console.log(err)
        resetWindowLocationWithoutParams(['fbct'])
      }
    }
  }

  const signInWithEmLink = async (redirectTo: string, email: string) => {
    const auth = getFirAuth()

    if (auth) {
      let actionCodeSettings = getActionCodeSettings(
        redirectTo || window.location.hostname
      )
      try {
        await sendSignInLinkToEmail(auth, email, actionCodeSettings)
        window.localStorage.setItem('emailForSignIn', email)
        return true
      } catch (err: any) {
        return false
      }
    }
    return false
  }

  // PHONE LOGIN //
  const sendPhoneVerification = async (phoneNumber: string) => {
    let auth = getFirAuth()
    let verifier = getAppVerifier('phone-signin-btn', auth)
    if (verifier && auth) {
      try {
        let confirmationResult: ConfirmationResult = await signInWithPhoneNumber(
          auth,
          phoneNumber,
          verifier
        )
        return confirmationResult
      } catch (err) {
        console.log(err)
        logEvent('loginError_sendPhoneVerification', {
          provider: 'phone',
          error: err,
          product: 'stats',
        })
        return null
      }
    } else {
      return null
    }
  }

  const getAppVerifier = (verifierId: string | HTMLElement, auth: Auth | undefined) => {
    if (auth) {
      return new RecaptchaVerifier(auth, verifierId, {
        size: 'invisible',
      })
    } else {
      return null
    }
  }

  const login = (extraFields?: string[]) => {
    if (extraFields) {
      setShowLogin({ show: true, extraFields })
      return
    }
    setShowLogin({ show: true, extraFields: [] })
  }

  const handleToggleLogin = () => {
    setShowLogin({ show: !setShowLogin, extraFields: [] })
  }

  const logout = () => {
    const fba = getFirAuth()
    if (fba) {
      setUser(NO_USER)
      fba.signOut()
      setBrazeInitialized(false)
      setShowLogin({ show: false, extraFields: [] })
      setAuthLoading(false)
    }
  }

  const loginWithBraze = async (userId: string, shouldSendEvent: boolean = true) => {
    //shouldSendEvent false for logging in through embeded browser
    if (!brazeInitialized) {
      await import('../../Config/Braze/braze.config').then((braze) => {
        let success = braze.initBraze(userId)
        if (success) {
          setBrazeInitialized(true)
          shouldSendEvent && logBrazeEvent(BRAZE_EVENTS.log_in)
        }
      })
    } else {
      shouldSendEvent && logBrazeEvent(BRAZE_EVENTS.log_in)
    }
  }

  const getAuthToken = async () => {
    const auth = getFirAuth()
    const usr = getCurrentUser()
    if (usr.isLoggedIn && auth) {
      return await auth.currentUser?.getIdToken()
    }
    return null
  }

  /**
   * Get fields user has not filled in on profile
   */
  const getMissingUserFields = (fields: string[]): string[] => {
    if (fields.length === 0) return []
    return fields.filter((field) => field in user && !user[field as keyof User])
  }

  return (
    <AuthContext.Provider
      value={{
        ...user,
        authLoading,
        brazeInitialized,
        phoneSentResult,
        recaptchaVerifier,
        handleToggleLogin,
        login,
        logout,
        sendPhoneVerification,
        getAuthToken,
        getMissingUserFields,
        updateProfileInState,
      }}
    >
      {showLogin.show && (
        <SignInModal
          onClose={() => {
            logEvent('login_cancelled')
            handleToggleLogin()
          }}
          signInWithSocial={signInWithSocial}
          signInWithEmLink={signInWithEmLink}
        />
      )}
      {children}
    </AuthContext.Provider>
  )
}
