import { FeedItem } from '../Feed/feed.types'
import { API_ENDPOINTS } from '../utils/endpoints'
import { QueryParams, makeAPIRequest } from '../utils/request'

export const fetchHighlightsBySlug = async (slug: string): Promise<FeedItem[]> => {
  try {
    const response = await makeAPIRequest(API_ENDPOINTS.GET_GAME_HIGHLIGHTS, {
      method: 'GET',
      apiVersion: '3',
      queryParams: { slug } as QueryParams,
    })
    if (!response?.json) return []
    return response.json as FeedItem[] || []
  } catch (err) {
    console.log(err)
    return []
  }
}
