
export function ShopifySearchResult({ hit, components }: { hit: any, components: any }) {
  return (
    <a href={`https://shop.premierlacrosseleague.com/products/${hit.handle}`} className="aa-ItemLink">
      <div className="aa-ItemContent">
        <img src={hit.image} style={{ width: 40, height: 40 }} />
        <div className="aa-ItemTitle">
          <components.Highlight hit={hit} attribute="title" />
        </div>
      </div>
    </a>
  );
}