import { initializeApp, FirebaseApp } from 'firebase/app'
import 'firebase/analytics'
import {
  Auth,
  initializeAuth,
  EmailAuthProvider,
  GoogleAuthProvider,
  indexedDBLocalPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  browserPopupRedirectResolver,
} from 'firebase/auth'
import { Analytics, getAnalytics } from 'firebase/analytics'
import { FIREBASE_CONFIG, USE_FIREBASE } from '../../Config/Firebase/firebase.config'

export type GCredential = {
  clientId: string
  credential: string
  select_by: string
}

export const AUTH_METHODS = [
  GoogleAuthProvider.PROVIDER_ID,
  {
    provider: EmailAuthProvider.PROVIDER_ID,
    signInMethod: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
  },
]

export const PROVIDERS = {
  google: new GoogleAuthProvider(),
  email: {
    provider: new EmailAuthProvider(),
    signInMethod: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
  },
}

let firebaseApp: FirebaseApp | undefined = undefined
let auth: Auth | undefined = undefined
export let analytics: Analytics | undefined = undefined
export let googleProvider: GoogleAuthProvider | undefined = undefined

export function initFirebase() {
  if (!USE_FIREBASE) return
  if (firebaseApp) return

    try {
      firebaseApp = initializeApp(FIREBASE_CONFIG)
      auth = initializeAuth(firebaseApp, {
        persistence: [
          indexedDBLocalPersistence,
          browserLocalPersistence,
          browserSessionPersistence,
        ],
        popupRedirectResolver: browserPopupRedirectResolver,
      })
      analytics = getAnalytics(firebaseApp)
    } catch (error) {
      console.error('Firebase initialization failed:', error)
      throw error
    }
}

export function getFirAuth(): Auth | undefined {
  return auth
}

export function getAuthProvider(): GoogleAuthProvider | undefined {
  return new GoogleAuthProvider()
}

export function getGoogleAuthCredential(cred: string): any | undefined {
  return GoogleAuthProvider.credential(cred)
}

export function getFirAnalytics(): Analytics {
  if (analytics === undefined) {
    throw new Error('no analytics access')
  }
  return analytics
}

export const getActionCodeSettings = (redirect: string) => {
  return {
    url: redirect,
    handleCodeInApp: true,
    iOS: {
      bundleId: process.env.REACT_APP_IOS_BUNDLE_ID || 'com.pll.PLL',
    },
    android: {
      packageName: process.env.REACT_APP_ANDROID_BUNDLE_ID || 'com.pll.pllandroid',
      installApp: true,
    },
  }
}
