import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ARTICLE_CARD_SIZE } from '../../Constants/consts'
import { StyleProps, SwiperCon} from './styles'

export type CardSwiperProps = {
  items: JSX.Element[]
  cardSize?: keyof typeof ARTICLE_CARD_SIZE
  desktopWidth?: number | string
  tabletWidth?: number | string
  mobileWidth?: number | string
  smMobileWidth?: number | string
  noMargin?: boolean
  idx?: string
  showPagination?: boolean
  showScrollbar?: boolean
  direction?: 'vertical' | 'horizontal'
}

export const CardSwiper: React.FC<React.PropsWithChildren<CardSwiperProps>> = ({
  items,
  desktopWidth,
  tabletWidth,
  mobileWidth,
  smMobileWidth,
  cardSize,
  noMargin = false,
  idx,
  direction = 'horizontal',
}) => {
  let props: StyleProps
  if (cardSize) {
    props = {
      noMargin,
      desktopWidth: ARTICLE_CARD_SIZE[cardSize].desktop,
      tabletWidth: ARTICLE_CARD_SIZE[cardSize].tablet,
      mobileWidth: ARTICLE_CARD_SIZE[cardSize].mobile,
      smMobileWidth: ARTICLE_CARD_SIZE[cardSize].smMobile,
    }
  } else {
    props = {
      noMargin,
      desktopWidth: desktopWidth || 224,
      tabletWidth: tabletWidth || 224,
      mobileWidth: mobileWidth || 224,
      smMobileWidth: smMobileWidth || 224,
    }
  }

  if (items.length < 1) {
    // avoid to render empty swiper, which may cause
    // internal swiper problems after rerender with content
    return null
  }

  return (
    <SwiperCon
      noMargin={props.noMargin}
      desktopWidth={props.desktopWidth}
      tabletWidth={props.tabletWidth}
      mobileWidth={props.mobileWidth}
      smMobileWidth={props.smMobileWidth}
    >
      <Swiper
        slidesPerView="auto"
        grabCursor
        preventClicks
        direction={direction}
        initialSlide={idx ? parseInt(idx) : 0}
      >
        {items.map((item, idx) => (
          <SwiperSlide className="swiperSlide" key={idx}>
            {item}
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperCon>
  )
}