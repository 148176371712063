
/**
 * Reset url after removing search param
 */
export const resetWindowLocationWithoutParams = (params: string[]) => {
  const currentUrl = window.location.href
  const updatedUrl = removeSearchParams(currentUrl, params)
  window.history.replaceState({ message: "URL updated" }, '', updatedUrl)
}

/**
 * Check permissions and remove unwanted parameters before copy
 */
export const copyLink = (text: string, params: string[]) => {
  let mergedParamsToRm = [...params, 'fbct'] //always remove token
  const updated = removeSearchParams(text, mergedParamsToRm)
  return navigator.clipboard.writeText(updated)
}

export const removeSearchParams = (lnk: string, params: string[]) => {
  if (params.length < 1) return lnk
  const url = new URL(lnk)
  params.forEach((param) => {
    url.searchParams.delete(param)
  })
  return url.toString()
}

export const checkIfEmbededInNativeApp = () => {
  if (/WKWebView/.test(navigator.userAgent)) {
    // Running in a WKWebView iOS
    return true
  } else if (/WebView/.test(navigator.userAgent)) {
    // Running in a UIWebView on iOS (older versions) or Android
    return true
  } else if(checkIfEmbededInPLLApp()) {
    // Running in PLL App
    return true
  } else if (window && window?.top !== window) {
    // Last check, if window.top is not equal to window, then we are in an iframe
    return true
  } else {
    // Not in an iframe
    return false
  }
}

/**
 * Use user agent to check if running in PLL App
 */
export const checkIfEmbededInPLLApp = () => {
  if (/pllmobile/.test(navigator.userAgent)) {
    // Running in PLL App
    return true
  }
  if (/pllmobile_android/.test(navigator.userAgent)) {
    // Running in PLL App
    return true
  }
  if (/pllmobile_iOS/.test(navigator.userAgent)) {
    // Running in PLL App
    return true
  }
  return false
}
