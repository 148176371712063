import { Outlet } from 'react-router-dom'
import { AdProvider } from '../../Context/AdContext/AdContext'
import { AuthProvider } from '../../Api/firebase'
import { SeasonProvider, VideoProvider } from '../../Context'

export const AppContextLayout: React.FC = () => {
  return (
    <AdProvider>
      <AuthProvider allowAnonymous>
        <SeasonProvider>
          <VideoProvider>
            <Outlet />
          </VideoProvider>
        </SeasonProvider>
      </AuthProvider>
    </AdProvider>
  )
}
