import { getFirAnalytics } from '../../Api/firebase/firebaseConnect'
import { logEvent as firLogEvent, setUserProperties } from "firebase/analytics"

type UserParams = {
    params: any
}

export const logEvent = (name: string, params?: any | undefined) => {
    const fa = getFirAnalytics()
    firLogEvent(fa, name, params)
}

export const setUserProperty = (property: UserParams) => {
    const fa = getFirAnalytics()
    setUserProperties(fa, property)
}