import { callGraphQL } from '../utils/request'
import {
  eventInfoAndStatsById,
  liveEventInfoAndStats,
  broadcastLiveEventInfoAndStats,
  seasonEventsQuery,
  playLogsByEvent,
  eventInProgressBySlug
} from './events.queries'
import {
  EventGQL,
  SeasonEventsResponse,
  EventResponse,
  EventPlayLogsResponse
} from './events.types'

/**
 * Fetch all events info using graphql
 */
export async function fetchSeasonEventsGQL(
  season: number,
  includeCS?: boolean
): Promise<EventGQL[]> {
  try {
    let evRes = await callGraphQL<SeasonEventsResponse>(seasonEventsQuery, {
      season: season,
      includeCS: includeCS || false
    })
    if(!evRes?.seasonEvents || evRes.seasonEvents.length < 1) return []
    return evRes.seasonEvents
  } catch (error) {
    return []
  }
}


/**
 * Fetch event info for particular officialId
 */
export async function fetchEventByIdGQL(id: string) {
  const ev = await callGraphQL<EventResponse>(eventInfoAndStatsById, {
    id: id,
  })
  return ev.event
}

/**
 * Fetch event info for live game
 */
 export async function fetchLiveEventByIdGQL(id: string) {
  const ev = await callGraphQL<EventResponse>(liveEventInfoAndStats, {
    id: id,
  })
  return ev.event
}

/**
 * Fetch event info for particular officialId
 */
export async function fetchEventInProgressBySlugGQL(
  slug: string
): Promise<EventResponse | null> {
  try {
    return callGraphQL<EventResponse>(eventInProgressBySlug, {
      slug: slug,
    })
  } catch (error) {
    return null
  }
}

/**
 * Fetch event info for live game
 */
 export async function fetchLiveEventBroadcastViewer(id: string) {
  const ev = await callGraphQL<EventResponse>(broadcastLiveEventInfoAndStats, {
    id: id,
  })
  return ev.event
}

/**
 * Fetch play logs for a single game
 */
export async function fetchPlayLogsByEvent(eventId: string) {
  const ev = await callGraphQL<EventPlayLogsResponse>(playLogsByEvent, {
    eventId,
  })
  return ev.playLogs
}