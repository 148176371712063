import React from 'react'
import logo from '../../images/PLLStats.png'
import { NavLink } from 'react-router-dom'
import { logEvent } from '../../Utils'
import { Box } from '../Layout'
import { colors } from '../../Constants/consts'

const Navbar = () => {
  const linkStyle = {
    fontWeight: 600,
    color: colors.yellow,
    borderBottom: `2px solid ${colors.yellow}`,
    borderBottomLeftRadius: '2px',
    borderBottomRightRadius: '2px'
  }


  return (
    <Box padding={0} vertMargins={0} sideMargins={0.1} rounded={6} color={colors.darkBackground}>
      <nav className="navbar">
        <div className="nav-center">
          <div className="nav-header">
            <NavLink
                  to="/">
              <img className="nav-icon" src={logo} alt="Premier Lacrosse League Logo" />
            </NavLink>
            <div className="nav-link-container">
              <div className="nav-links">
                <NavLink
                  to="/"
                  end
                  onClick={() =>
                    logEvent('stats_nav_click', {
                      tab: 'Home',
                      link: '/',
                    })
                  }
                  style={({ isActive }) =>
                  isActive ? linkStyle : undefined }
                >
                  Home
                </NavLink>
                <NavLink
                  to="/players"
                  onClick={() =>
                    logEvent('stats_nav_click', {
                      tab: '{Players}',
                      link: '/players',
                    })
                  }
                  style={({ isActive }) =>
                  isActive ? linkStyle : undefined }
                >
                  Players
                </NavLink>
                <NavLink
                  to="/teams"
                  onClick={() =>
                    logEvent('stats_nav_click', {
                      tab: 'Teams',
                      link: '/teams',
                    })
                  }
                  style={({ isActive }) =>
                  isActive ? linkStyle : undefined }
                >
                  Teams
                </NavLink>
                <NavLink
                  to="/games"
                  onClick={() =>
                    logEvent('stats_nav_click', {
                      tab: 'Games',
                      link: '/games',
                    })
                  }
                  style={({ isActive }) =>
                  isActive ? linkStyle : undefined }
                >
                  Games
                </NavLink>
                <NavLink
                  to="/fantasy"
                  onClick={() =>
                    logEvent('stats_nav_click', {
                      tab: 'Fantasy',
                      link: '/fantasy',
                    })
                  }
                  style={({ isActive }) =>
                  isActive ? linkStyle : undefined }
                >
                  Fantasy
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </Box>
  )
}

export default Navbar
