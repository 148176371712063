import React from 'react'
import { Skeleton } from '@mui/material'
import { TableLoadRoot } from './styles'

type LoadProps = {
  fullHeight?: boolean
}

const TableLoading: React.FC<LoadProps> = ({ fullHeight = false }) => {
  return (
    <TableLoadRoot fullHeight={fullHeight}>
      <div className="tRow">
        <Skeleton
          className="avatar"
          variant="circular"
          width={40}
          height={40}
          animation="pulse"
        />
        <Skeleton variant="text" animation="pulse" width={'100%'} />
      </div>
      <div className="tRow">
        <Skeleton
          className="avatar"
          variant="circular"
          width={40}
          height={40}
          animation="pulse"
        />
        <Skeleton variant="text" animation="pulse" width={'100%'} />
      </div>
      <div className="tRow">
        <Skeleton
          className="avatar"
          variant="circular"
          width={40}
          height={40}
          animation="pulse"
        />
        <Skeleton variant="text" animation="pulse" width={'100%'} />
      </div>
      <div className="tRow">
        <Skeleton
          className="avatar"
          variant="circular"
          width={40}
          height={40}
          animation="pulse"
        />
        <Skeleton variant="text" animation="pulse" width={'100%'} />
      </div>
      <div className="tRow">
        <Skeleton
          className="avatar"
          variant="circular"
          width={40}
          height={40}
          animation="pulse"
        />
        <Skeleton variant="text" animation="pulse" width={'100%'} />
      </div>
      <div className="tRow">
        <Skeleton
          className="avatar"
          variant="circular"
          width={40}
          height={40}
          animation="pulse"
        />
        <Skeleton variant="text" animation="pulse" width={'100%'} />
      </div>
    </TableLoadRoot>
  )
}

export default TableLoading
