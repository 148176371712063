import { Outlet } from 'react-router-dom'
import { BroadcastGameProvider } from '../../Context'

export const BroadcastViewerContextLayout: React.FC = () => {
  return (
    <BroadcastGameProvider>
      <Outlet />
    </BroadcastGameProvider>
  )
}
